import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Gavel from "@mui/icons-material/Gavel";
import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

export default function TermsOfUse() {
    return (
        <Box
            id="hero"
            sx={{
                width: '100%',
                backgroundColor: '#14181b'
            }}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    pt: {xs: 14, sm: 20},
                    pb: {xs: 8, sm: 12, lg: 0},
                }}
            >
                <Stack spacing={2} useFlexGap sx={{width: {xs: '100%', sm: '70%'}}}>
                    <Typography variant="subtitle1" color='#fff' sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                                fontWeight="700" fontSize={{
                        lg: 45,
                        md: 45,
                        sm: 40,
                        xs: 30
                    }} gutterBottom>
                        Terms of Use <Gavel
                        sx={{
                            color: '#EECC6E', fontSize: {
                                lg: 55,
                                md: 50,
                                sm: 40,
                                xs: 30
                            }
                        }}/></Typography>
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={18}
                                gutterBottom>
                        Effective Date: 2024-03-10 </Typography>
                    <Typography variant="subtitle2" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={18}
                                gutterBottom>
                        These terms and conditions ("Terms") outline the rules for using our mobile application
                        AiDollify (the "App"). By downloading or using the App, you agree to be bound by these Terms.
                        Please read them carefully before using the App. </Typography>
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={18}
                                gutterBottom>
                        Ownership and Intellectual Property </Typography>
                    <Typography variant="subtitle2" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={18}
                                gutterBottom>
                        AiDollify and all its underlying code, trademarks, copyrights, and other intellectual property rights belong to AiDollify. </Typography>

                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={18}
                                gutterBottom>
                        Modification and Restrictions </Typography>
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={18}>
                        You are not allowed to:
                    </Typography>

                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={18}
                                gutterBottom>
                        <ul>
                            <li>Copy or modify the App, any part of it, or our trademarks.</li>
                            <li>Attempt to extract the source code of the App.</li>
                            <li>Translate the App to other languages or create derivative versions.</li>
                        </ul>
                    </Typography>

                    {/*Changes to the App and Services*/}
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={18}
                                gutterBottom>
                        Changes to the App and Services
                    </Typography>
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={18}
                                gutterBottom>
                        We reserve the right to update the App, modify its features, or change its pricing at any time. We will always notify you about any significant changes.
                    </Typography>

                    {/*Data Security*/}
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={18}
                                gutterBottom>
                        Data Security
                    </Typography>
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={18}
                                gutterBottom>
                        AiDollify stores and processes personal data you provide to use the Service. You are responsible for maintaining the security of your device and app access. We recommend against jailbreaking or rooting your device, as it can compromise security and prevent the App from functioning properly.
                    </Typography>

                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={18}
                                gutterBottom>
                        Third-Party Services
                    </Typography>
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={18}>
                        The App uses third-party services with their own Terms and Conditions. Links are provided below for your reference:
                    </Typography>
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={18}
                                gutterBottom>
                        <ul>
                            <li><a href="https://www.google.com/policies/privacy/">Google Play Services</a></li>
                            <li><a href="https://firebase.google.com/support/privacy">Google Analytics for Firebase</a></li>
                            <li><a href="https://www.revenuecat.com/privacy">RevenueCat</a></li>
                        </ul>
                    </Typography>

                    {/*Network Connectivity and Charges*/}
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={18}
                                gutterBottom>
                        Network Connectivity and Charges
                    </Typography>
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={18}
                                gutterBottom>
                        Certain App functionalities require an internet connection (Wi-Fi or mobile data). You are responsible for any data usage charges incurred while using the App. This includes roaming charges if you use the App outside your home territory.
                    </Typography>

                    {/*Device Compatibility and Updates*/}
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={18}
                                gutterBottom>
                        Device Compatibility and Updates
                    </Typography>
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={18}
                                gutterBottom>
                        The App is currently available on Android and iOS - (coming soon). The system requirements may change, and you may need to download updates to keep using the App. While we strive to keep the App updated, we do not guarantee compatibility with any specific device or future software versions. We recommend installing all updates offered for the App.                    </Typography>

                    {/*Termination*/}
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={18}
                                gutterBottom>
                        Termination
                    </Typography>
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={18}
                                gutterBottom>
                        We may stop providing the App or terminate your use at any time without notice. Upon termination, your rights to use the App will cease, and you must uninstall it from your device.                    </Typography>

                    {/*Changes to these Terms*/}
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={18}
                                gutterBottom>
                        Changes to these Terms
                    </Typography>
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={18}
                                gutterBottom>
                        We may update these Terms periodically. We will notify you of any changes by posting the new Terms here.
                    </Typography>

                    {/*Contact Us*/}
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={18}
                                gutterBottom>
                        Contact Us
                    </Typography>
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={18}
                                gutterBottom>
                        If you have any questions about these Terms, please contact us at: &nbsp;
                        <a href="#">aidollify@gmail.com</a>
                    </Typography>

                </Stack>
            </Container>
        </Box>
    );
}
