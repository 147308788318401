import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {CardActionArea, Chip, Container, Stack} from '@mui/material';
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import MagicIcon from "@mui/icons-material/AutoAwesome";
import Divider from "@mui/material/Divider";

export default function ModelCard() {
    return (
        <Box
            id="model-card"
            sx={{
                width: '100%',
                backgroundColor: '#14181b'
            }}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: {xs: 60, sm: 20, lg: 0},
                    pb: {xs: 10, sm: 12},
                }}
            >
                <Grid container spacing={6} sx={{display: {lg: 'none', md: 'none'}}}>
                    <Grid item xs={12} md={6}>
                        <Grid container direction="row" gap={2}
                              justifyContent="center"
                              alignItems="center">
                            <Card sx={{borderRadius: 3}}>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="150"
                                        image="https://pub-3626123a908346a7a8be8d9295f44e26.r2.dev/generations/a2a6ce03-8fb3-400b-89e3-e87e84db0932-0.png"
                                        alt="Anything-v5"
                                    />
                                    <CardContent sx={{
                                        background: '#262d34',
                                        height: 10,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <Typography variant="body2" color='#fff'
                                                    sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                                                    fontWeight="bold">
                                            Anything-v5
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                            <Card sx={{borderRadius: 3}}>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="150"
                                        image="https://pub-3626123a908346a7a8be8d9295f44e26.r2.dev/generations/d5c9e5a4-d872-4b36-b24a-98cb5220c6bf-0.png"
                                        alt="SDXL"
                                    />
                                    <CardContent sx={{
                                        background: '#262d34',
                                        height: 10,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <Typography variant="body2" color='#fff'
                                                    sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                                                    fontWeight="bold">
                                            SDXL
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                            <Card sx={{borderRadius: 3}}>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="150"
                                        image="https://pub-3626123a908346a7a8be8d9295f44e26.r2.dev/generations/ee2a0204-8cd5-4ef4-b74a-1155e1e51d86-0.png"
                                        alt="Revanimated"
                                    />
                                    <CardContent sx={{
                                        background: '#262d34',
                                        height: 10,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <Typography variant="body2" color='#fff'
                                                    sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                                                    fontWeight="bold">
                                            Revanimated
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                            <Card sx={{borderRadius: 3}}>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="150"
                                        image="https://pub-3626123a908346a7a8be8d9295f44e26.r2.dev/generations/ee8df3d2-6a80-4bc6-a365-688129a89854-0.png"
                                        alt="Meina-hentai"
                                    />
                                    <CardContent sx={{
                                        background: '#262d34',
                                        height: 10,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <Typography variant="body2" color='#fff'
                                                    sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                                                    fontWeight="bold">
                                            Meina-hentai
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                            <Card sx={{borderRadius: 3}}>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="150"
                                        image="https://pub-3626123a908346a7a8be8d9295f44e26.r2.dev/generations/2c3975a9-3450-4300-8f1b-f116b8bf0745-0.png"
                                        alt="Dark-sushi-25d"
                                    />
                                    <CardContent sx={{
                                        background: '#262d34',
                                        height: 10,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <Typography variant="body2" color='#fff'
                                                    sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                                                    fontWeight="bold">
                                            Dark-sushi-25d
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                            <Card sx={{borderRadius: 3}}>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="150"
                                        image="https://pub-3626123a908346a7a8be8d9295f44e26.r2.dev/generations/b4786229-c10a-4d24-b359-ac1479121a42-0.png"
                                        alt="Crystal-clear-xlv1"
                                    />
                                    <CardContent sx={{
                                        background: '#262d34',
                                        height: 10,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <Typography variant="body2" color='#fff'
                                                    sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                                                    fontWeight="bold">
                                            Crystal-clear-xlv1
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        direction="column"
                        sx={{width: '100%'}}
                    >
                        <Typography variant="subtitle1" color='#C30460'
                                    sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={35}
                                    gutterBottom>
                            Customize Your Look: Design Avatars with AiDollify! </Typography>
                        <Typography variant="subtitle2" color='#95A1AC'
                                    sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={15}
                                    gutterBottom>
                            Discover a universe of unique custom models with Ai Dollify. Design your dream avatar with a
                            variety of options to express your individuality perfectly. </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={6} sx={{display: {xs: 'none', lg: 'flex', md: 'flex'}}}>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        direction="column"
                        sx={{width: '100%'}}
                    >
                        <Typography variant="subtitle1" color='#C30460'
                                    sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={35}
                                    gutterBottom>
                            Customize Your Look: Design Avatars with AiDollify! </Typography>
                        <Typography variant="subtitle2" color='#95A1AC'
                                    sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={15}
                                    gutterBottom>
                            Discover a universe of unique custom models with Ai Dollify. Design your dream avatar with a
                            variety of options to express your individuality perfectly. </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container direction="row" gap={2}
                              justifyContent="center"
                              alignItems="center">
                            <Card sx={{borderRadius: 3}}>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="150"
                                        image="https://pub-3626123a908346a7a8be8d9295f44e26.r2.dev/generations/a2a6ce03-8fb3-400b-89e3-e87e84db0932-0.png"
                                        alt="Anything-v5"
                                    />
                                    <CardContent sx={{
                                        background: '#262d34',
                                        height: 10,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <Typography variant="body2" color='#fff'
                                                    sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                                                    fontWeight="bold">
                                            Anything-v5
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                            <Card sx={{borderRadius: 3}}>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="150"
                                        image="https://pub-3626123a908346a7a8be8d9295f44e26.r2.dev/generations/d5c9e5a4-d872-4b36-b24a-98cb5220c6bf-0.png"
                                        alt="SDXL"
                                    />
                                    <CardContent sx={{
                                        background: '#262d34',
                                        height: 10,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <Typography variant="body2" color='#fff'
                                                    sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                                                    fontWeight="bold">
                                            SDXL
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                            <Card sx={{borderRadius: 3}}>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="150"
                                        image="https://pub-3626123a908346a7a8be8d9295f44e26.r2.dev/generations/ee2a0204-8cd5-4ef4-b74a-1155e1e51d86-0.png"
                                        alt="Revanimated"
                                    />
                                    <CardContent sx={{
                                        background: '#262d34',
                                        height: 10,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <Typography variant="body2" color='#fff'
                                                    sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                                                    fontWeight="bold">
                                            Revanimated
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                            <Card sx={{borderRadius: 3}}>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="150"
                                        image="https://pub-3626123a908346a7a8be8d9295f44e26.r2.dev/generations/ee8df3d2-6a80-4bc6-a365-688129a89854-0.png"
                                        alt="Meina-hentai"
                                    />
                                    <CardContent sx={{
                                        background: '#262d34',
                                        height: 10,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <Typography variant="body2" color='#fff'
                                                    sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                                                    fontWeight="bold">
                                            Meina-hentai
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                            <Card sx={{borderRadius: 3}}>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="150"
                                        image="https://pub-3626123a908346a7a8be8d9295f44e26.r2.dev/generations/2c3975a9-3450-4300-8f1b-f116b8bf0745-0.png"
                                        alt="Dark-sushi-25d"
                                    />
                                    <CardContent sx={{
                                        background: '#262d34',
                                        height: 10,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <Typography variant="body2" color='#fff'
                                                    sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                                                    fontWeight="bold">
                                            Dark-sushi-25d
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                            <Card sx={{borderRadius: 3}}>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="150"
                                        image="https://pub-3626123a908346a7a8be8d9295f44e26.r2.dev/generations/b4786229-c10a-4d24-b359-ac1479121a42-0.png"
                                        alt="Crystal-clear-xlv1"
                                    />
                                    <CardContent sx={{
                                        background: '#262d34',
                                        height: 10,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <Typography variant="body2" color='#fff'
                                                    sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                                                    fontWeight="bold">
                                            Crystal-clear-xlv1
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider sx={{pb: {lg: 10, md: 10, xs: 3.5}}}/>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Chip label="Ai Avatar Generator" icon={<MagicIcon sx={{fontSize: 25}}/>} sx={{
                        backgroundColor: '#C30460',
                        fontFamily: '"Plus Jakarta Sans", sans-serif',
                        color: '#5C032D',
                        fontWeight: '700',
                        fontSize: 20,
                        '& .MuiChip-icon': {
                            color: '#FFBF00',
                        },
                    }}/>
                    <Typography variant="subtitle1" color='#95A1AC' sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                                fontWeight="bold" fontSize={{
                        lg: 60,
                        md: 50,
                        sm: 40,
                        xs: 30
                    }} gutterBottom>
                        The 'Custom Model' Image Generating App </Typography>
                </Stack>
            </Container>
        </Box>
    );
}
