import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MagicIcon from '@mui/icons-material/AutoAwesome';
import Grid from "@mui/material/Grid";
import {ImageList, ImageListItem} from "@mui/material";

const itemData = [
    {
        img: 'https://pub-3626123a908346a7a8be8d9295f44e26.r2.dev/generations/d57bbf4b-d803-4e1f-b396-6aa4240066c2-0.png',
        title: 'Anime Girl',
        cols: 2,
        rows: 2
    },
    {
        img: 'https://pub-3626123a908346a7a8be8d9295f44e26.r2.dev/generations/28ce4d9e-1471-423a-87ea-1089d9049f98-0.png',
        title: 'Futuristic cityscape',
        cols: 2,
        rows: 2
    },
    {
        img: 'https://pub-3626123a908346a7a8be8d9295f44e26.r2.dev/generations/20ee5175-7f99-4c6b-b265-daf6979f1736-0.png',
        title: 'Space Station Girl',
        cols: 2,
        rows: 2
    },
    {
        img: 'https://pub-3626123a908346a7a8be8d9295f44e26.r2.dev/generations/a1389acb-881e-43a2-905a-3c38cf249c16-0.png',
        title: 'Busty Girl',
        cols: 2,
        rows: 2
    }
];

function srcset(image, size, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${
            size * rows
        }&fit=fit&auto=format`,
    };
}

export default function Hero() {
    return (
        <Box
            id="hero"
            sx={{
                width: '100%',
                backgroundColor: '#14181b'
            }}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: {xs: 14, sm: 20},
                    pb: {xs: 8, sm: 12, lg: 0},
                }}
            >
                <Stack spacing={2} useFlexGap sx={{width: {xs: '100%', sm: '70%'}}}>
                    <Typography variant="subtitle1" color='#fff' sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                                fontWeight="700" fontSize={{
                        lg: 60,
                        md: 50,
                        sm: 40,
                        xs: 30
                    }} gutterBottom>
                        Express yourself today. Design your avatar with AiDollify! <MagicIcon
                        sx={{color: '#FFBF00', fontSize: {
                                lg: 60,
                                md: 50,
                                sm: 40,
                                xs: 30
                            }}}/> </Typography>
                </Stack>
                <Box
                    sx={{
                        mt: {xs: 8, sm: 10},
                        alignSelf: 'center',
                        height: {xs: 200, sm: 700},
                    }}
                >
                    <Grid container spacing={6}>
                        <Grid item xs={12} md={6}>
                            <ImageList
                                variant="quilted"
                                cols={4}
                                gap={15}
                            >
                                {itemData.map((item) => (
                                    <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                                        <img
                                            {...srcset(item.img, item.rows, item.cols)}
                                            alt={item.title}
                                            style={{borderRadius: 15}}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            direction="column"
                            sx={{ width: '100%'}}
                        >
                            <Typography variant="subtitle1" color='#C30460'
                                        sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={35}
                                        gutterBottom>
                                Unleash endless avatar possibilities!
                            </Typography>
                            <Typography variant="subtitle2" color='#95A1AC'
                                        sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={15}
                                        gutterBottom>
                                Discover a universe of unique avatar styles with Ai Dollify. Get inspired and design your
                                dream avatar with the power of AI.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}
