import * as React from 'react';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import logo from '../images/logo.png';

const logoStyle = {
  width: '80px',
  height: 'auto',
  cursor: 'pointer',
};

function AppAppBar() {
    return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 2,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '15px',
              bgcolor: 'rgba(206, 3, 95, 0.3)',
              backdropFilter: 'blur(24px)',
              maxHeight: 40,
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                ml: '-18px',
                px: 0,
              }}
            >
              <img
                src={logo}
                style={logoStyle}
                alt="logo of sitemark"
              />
                <Typography ml={1} variant="subtitle1" color='#C30460'
                            sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                            fontWeight="bold" fontSize={28} gutterBottom>
                    AiDollify</Typography>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default AppAppBar;
