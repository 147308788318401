import * as React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import Pricing from './components/Pricing';
import Footer from './components/Footer';
import getLPTheme from './getLPTheme';
import ModelCard from "./components/ModelCard";


export default function LandingPage() {

    const [mode] = React.useState('dark');
    const [showCustomTheme] = React.useState(true);
    const LPtheme = createTheme(getLPTheme(mode));
    const defaultTheme = createTheme({palette: {mode}});

    return (
        <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
            <CssBaseline/>
            <AppAppBar/>
            <Hero/>
            <Box sx={{bgcolor: '#14181b'}}>
                <ModelCard/>
                <Divider/>
                <Pricing/>
                <Divider/>
                <Footer/>
            </Box>
        </ThemeProvider>
    );
}
