import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

const tiers = [
    {
        title: 'Free',
        price: '0',
        description: [
            '5 Image Generations Per Week',
            'Access to all models',
            'Instant Image Generation',
            'Infinite Album Creation',
        ],
        buttonText: 'Sign up for free',
        buttonVariant: 'outlined',
    },
    {
        title: 'Annual',
        subheader: 'Recommended',
        price: '89.99',
        description: [
            '2 months free',
            '100 Image Generations Per Week',
            'Hide Images From Public View',
            'View Public Image Prompts',
            'Save AI Images'
        ],
        buttonText: 'Start now',
        buttonVariant: 'contained',
    },
    {
        title: 'Monthly',
        price: '8.99',
        description: [
            '100 Image Generations Per Week',
            'Hide Images From Public View',
            'View Public Image Prompts',
            'Save AI Images'
        ],
        buttonText: 'Contact us',
        buttonVariant: 'outlined',
    },
];

export default function Pricing() {
    return (
        <Container
            id="pricing"
            sx={{
                pt: {xs: 4, sm: 12},
                pb: {xs: 8, sm: 16},
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: {xs: 3, sm: 6},
            }}
        >
            <Box
                sx={{
                    width: {sm: '100%', md: '60%'},
                    textAlign: {sm: 'left', md: 'center'},
                }}
            >
                <Typography variant="subtitle1" color='#95A1AC' sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                            fontWeight="bold" fontSize={{
                    lg: 60,
                    md: 50,
                    sm: 40,
                    xs: 30
                }} gutterBottom>
                    Pricing
                </Typography>
                <Typography variant="subtitle2" color='#95A1AC'
                            sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={15}
                            gutterBottom>
                    Unlock more customization options with higher tiers!. Find the perfect plan to fit your needs and
                    budget.
                    Each tier offers more features to help you create your dream avatar.
                </Typography>
            </Box>
            <Grid container spacing={3} alignItems="center" justifyContent="center">
                {tiers.map((tier) => (
                    <Grid
                        item
                        key={tier.title}
                        xs={12}
                        sm={tier.title === 'Enterprise' ? 12 : 6}
                        md={4}
                    >
                        <Card
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 4,
                                border: tier.title === 'Annual' ? '1px solid' : undefined,
                                borderColor:
                                    tier.title === 'Annual' ? '#C30460' : undefined,
                                background:
                                    tier.title === 'Annual'
                                        ? 'rgba(206, 3, 95, 0.3)'
                                        : undefined,
                            }}
                        >
                            <CardContent>
                                <Box
                                    sx={{
                                        mb: 1,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        fontFamily: '"Plus Jakarta Sans", sans-serif',
                                        alignItems: 'center',
                                        color: tier.title === 'Annual' ? 'grey.100' : '',
                                    }}
                                >
                                    <Typography component="h3" sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                                                fontWeight="bold" variant="h6">
                                        {tier.title}
                                    </Typography>
                                    {tier.title === 'Annual' && (
                                        <Chip
                                            icon={<AutoAwesomeIcon/>}
                                            label={tier.subheader}
                                            size="small"
                                            sx={{
                                                backgroundColor: '#C30460',
                                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                                color: '#5C032D',
                                                fontWeight: '700',
                                                '& .MuiChip-icon': {
                                                    color: '#FFBF00',
                                                },
                                            }}
                                        />
                                    )}
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'baseline',
                                        color: tier.title === 'Annual' ? 'grey.50' : undefined,
                                    }}
                                >
                                    <Typography component="h3" sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                                                fontWeight="bold" variant="h2">
                                        ${tier.price}
                                    </Typography>
                                    <Typography component="h3" sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                                                variant="h6">
                                        &nbsp; per month
                                    </Typography>
                                </Box>
                                <Divider
                                    sx={{
                                        my: 2,
                                        opacity: 0.2,
                                        borderColor: 'grey.500',
                                    }}
                                />
                                {tier.description.map((line) => (
                                    <Box
                                        key={line}
                                        sx={{
                                            py: 1,
                                            display: 'flex',
                                            gap: 1.5,
                                            alignItems: 'center',
                                        }}
                                    >
                                        <CheckCircleRoundedIcon
                                            sx={{
                                                width: 20,
                                                color: '#C30460',
                                            }}
                                        />
                                        <Typography
                                            component="text"
                                            variant="subtitle2"
                                            sx={{
                                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                                color:
                                                    tier.title === 'Annual' ? 'grey.200' : undefined,
                                            }}
                                        >
                                            {line}
                                        </Typography>
                                    </Box>
                                ))}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}
